import './App.css';
import { useEffect, useState } from 'react';
import { addDoc, collection, doc, getFirestore, onSnapshot,getDoc,deleteDoc,setDoc,updateDoc,where,serverTimestamp, arrayUnion, increment, query } from 'firebase/firestore';
import { db, firestore } from './firebase';
import { initializeApp } from 'firebase/app';
import { v4 } from 'uuid';

export default function Withdrawal() {
    const [totalUsers,setTotalUsers] = useState([])
    const [teachers,setteachers] = useState([])
    const [students,setstudents] = useState([])
    const [transactions,settransactions] = useState([])
    const [studentName,setstudentName] = useState('')
    const [declinedReason,setdeclinedReason] = useState('')
    const [alreadyExists,setalreadyExists] = useState('')
    const [success,setsuccess] = useState(false)
  const [reason,setreason] = useState(false)


    const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString();
      };
    
      useEffect(()=>{
   
        const totalUsersCollectionRef = collection(db, 'TotalUsers');
        
        onSnapshot(totalUsersCollectionRef, (snapshot) => {
          let temp = []
          snapshot.forEach((doc) => {
            temp.push({ ...doc.data() })
            setTotalUsers(temp);
          });
        });
    
        const transactions = collection(db, 'Withdrawal');
        const q = query(transactions, where('status', '==', 'Pending'));
        
        onSnapshot(q, (snapshot) => {
          let temp = []
          snapshot.forEach((snapshot) => {
            temp.push({ ...snapshot.data(),transactionID:snapshot.id })
            settransactions(temp);
   
           
          });
        });
    

        
    
     
    
    
      },[])
    
      async function handleApprove(
        transactionID,
        uid,
    
      ) {
        try {
            const documentRef3 = doc(firestore, "AccountDetails", uid, "Transaction", transactionID);
            const documentRef5 = doc(firestore, "Transactions", transactionID);
            const documentRef6 = doc(firestore, "TotalUsers", uid);
            const documentRef7 = doc(firestore, "TransactionWithdrawal", transactionID);
            const documentRef8 = doc(firestore, "Withdrawal", transactionID);
      
          const data = {
            state: 'Approved'
          };
      
      
      
      
    
          await updateDoc(
           documentRef3,
            data
          );

          await updateDoc(
            documentRef5,
             data
           );

           await updateDoc(
            documentRef7,
             data
           );
           await updateDoc(
            documentRef8,
             data
           );
    
      
          await setDoc(doc(firestore, 'AccountDetails', uid, 'Notification', v4()), {
            notificationTitle: `you have successfully withdrawn your money`,
            time: serverTimestamp()
          });
      
         
      
          await updateDoc(doc(firestore, 'TotalUsers', uid), {
            pendingWithdrawal:false
        });
      
     
   
      
      
         
      
       
      
      
        } catch (error) {
          console.error('Error handling approval:', error);
        }
          setsuccess(true);
      
         
      
      
          
        
      }
      function handleDecline(transactionID,buyerId,sellerId,courseId){
        const data ={
          paid:false,
          isPending:false,
          state: 'Declined',
          declinedReason:declinedReason
    
    
        }
        updateDoc(doc(firestore,'TransactionCourses',transactionID),data).then(
          updateDoc(doc(firestore,'AccountDetails',buyerId,'Transaction',transactionID),data).then(
          updateDoc(doc(firestore,'AccountDetails',sellerId,'Transaction',transactionID),data).then(
    
          updateDoc(doc(firestore,'AccountDetails',buyerId,'Courses',courseId),data).then(
          )
          )
    
          )
        )
      }
    
      
      function decline(transactionID,paymentFor,uid){
        setreason(true)
    
      }
  return (
    <div>  <div className="App">
   
   

      <table class="w-full min-w-max table-auto text-left">
  <thead>
    <tr>
    <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="text-center Mooli block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Transaction Id</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Account Holder</p>
      </th>

   

      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Account Number</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Amount</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Date</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Status</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Bank</p>
      </th>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
        <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70"></p>
      </th>
    </tr>
  </thead>
{
  transactions.map(i=>{
    const {accountName,accountNumber,bank,status,courseId,buyerId,userId,screenshot,amount,transactionID,chosenMethod,paid,isPending,createdTime} = i
    return(
      <tbody>
      <tr>
        <td class="p-4 border-b border-blue-gray-50">
          <div class="flex items-center gap-3">
            {/* <img src="https://docs.material-tailwind.com/img/logos/logo-spotify.svg" alt="Spotify" class="inline-block relative object-center !rounded-full w-12 h-12 rounded-lg border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"/> */}
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 ">{transactionID}</p>
          </div>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{accountName}</p>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{accountNumber}</p>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{amount} ETB</p>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{convertTimestampToDate(createdTime)}</p>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <div class="w-max">
           {
            status == 'Approved' ?
            <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
            <span class="">Paid</span>
          </div>
          :
          status == 'Declined' ?
          <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-red-500/20 text-red-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
          <span class="">Declined</span>
        </div>
        :
        <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-yellow-500/20 text-yellow-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
        <span class="">Pending</span>
      </div>
           }
          </div>
        </td>
        <td class="p-4 border-b border-blue-gray-50">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{bank}</p>
        </td>

        <td class="p-4 border-b border-blue-gray-50">
          <a style={{width:"30%"}}  onClick={()=>decline()} class="mx-2 px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Decline</a>
 <a style={{width:"30%"}}  onClick={()=>handleApprove(transactionID,userId)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Approve</a>

        </td>
      </tr>
      {
  reason == true && 
      
<div className='flex items-center'>
    <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}   onChange={e=>setdeclinedReason(e.target.value)}  type="text" class="border  focus:outline-none
               focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
               border-gray-300 rounded-md" maxLength="5000"></textarea>
 <a style={{width:"30%"}}  onClick={()=>handleDecline(transactionID,userId)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Decline</a>

               
</div>
}
  
   
    </tbody>
    )
  })
}
</table>
  </div></div>
  )
}
