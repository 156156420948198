import React from 'react'
import { Link } from 'react-router-dom'

export default function Dashboard() {
  return (
    <div style={{width:'100%',height:'100vh'}} className='flex  items-center '>
            <Link to='/user' class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">
   <a   >New User</a>
  
            </Link>
            <Link to='/coursereview' class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">
   <a   >Course Review</a>
  
            </Link>
            <Link to='/course' class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">

   <a    >Course Confirmation</a>
  
            </Link>
            <Link to='/withdrawal' class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">

<a    >Withdrawal</a>

         </Link>

            <Link to='/complaints' class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">
   <a    >Complaints</a>
  
            </Link>


    </div>
  )
}
