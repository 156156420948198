import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { addDoc, collection, deleteDoc, doc, getFirestore, onSnapshot,query,serverTimestamp,setDoc,updateDoc,where } from 'firebase/firestore';
import { db, firestore } from './firebase';
import { initializeApp } from 'firebase/app';
import { v4 } from 'uuid';
import moment from 'moment';

export default function User() {
  const [newUsers,setNewUsers] = useState([])
  const [transactions,settransactions] = useState([])
  const [totalUsers,setTotalUsers] = useState([])
  const [teachers,setteachers] = useState([])
  const [students,setstudents] = useState([])

  const [declinedReason,setdeclinedReason] = useState(null)
  const [reason,setreason] = useState(false)

  const [totalStudents,setTotalStudents] = useState(null)


  const [student,setstudent] = useState({})
  const [nextMonth,setnextMonth] = useState()




  function calculateMembershipLength(membershipType, currentDate) {
    const currentMoment = moment(currentDate, 'YYYY-MM-DD HH:mm:ss');
    const currentYear = currentMoment.year();
    const currentMonth = currentMoment.month();
  
    let nextMonth;
    let nextMonthFirstDay;
  
    if (membershipType === 'year') {
      nextMonth = currentMonth;
      nextMonthFirstDay = moment([currentYear + 1, nextMonth, 1]);
    } else if (membershipType === 'threeMonths') {
      nextMonth = (currentMonth + 3) % 12;
      nextMonthFirstDay = moment([currentYear, nextMonth, 1]);
    } else if (membershipType === 'month') {
      nextMonth = (currentMonth + 1) % 12;
      const daysInNextMonth = moment([currentYear, nextMonth + 1, 0]).date();
      const currentDay = currentMoment.date();
  
      if (currentDay > daysInNextMonth) {
        nextMonthFirstDay = moment([currentYear, nextMonth, daysInNextMonth]);
      } else {
        nextMonthFirstDay = moment([currentYear, nextMonth, currentDay]);
      }
    } else {
      throw new Error('Invalid membership type');
    }
  
    const formattedNextMonthFirstDay = nextMonthFirstDay.format('YYYY-MM-DD HH:mm:ss');
    return formattedNextMonthFirstDay;
  }


  useEffect(()=>{
   
    const totalUsersCollectionRef = collection(db, 'TransactionNewUsers');
    
    onSnapshot(totalUsersCollectionRef, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        setNewUsers(temp);
      });
    });


    const transactions = collection(db, 'TransactionNewUsers');
    
    onSnapshot(transactions, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        settransactions(temp);
      });
    });

    

  },[])

  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };




  function handleApprove(transactionID,paymentFor,uid,plan,length){
  
    const duration = length == 'annual' ? 'year' : length == 'month' ? 'month' : 'threeMonths'
    const currentTime = moment();
console.log(transactionID)

    const data ={
      paid:true,
      isPending:false,
      state:'Approved',
      billingDate:calculateMembershipLength(duration,currentTime.format('YYYY-MM-DD HH:mm:ss'))

    }

    setDoc(doc(firestore,'Transactions',transactionID),data,{merge:true}).then(
      setDoc(doc(firestore,'AccountDetails',uid,'Transaction',transactionID),data,{merge:true}).then(
      ).then(
        
      setDoc(doc(firestore,'TotalUsers',uid),{
        paid:true,
        isPending:false,
        plan:paymentFor,
        billingDate:calculateMembershipLength(duration,currentTime.format('YYYY-MM-DD HH:mm:ss')),
        state:'Approved',
        length: length
      
        
   

        
      },{merge:true}).then(
      //   deleteDoc(doc(firestore,'TransactionNewUsers',uid)).then(()=>{
      // settransactions(prevArray => prevArray.filter(item => item.uid !== uid));

      //   })
      ).then(
        setDoc(doc(firestore,'AccountDetails',uid,'Notification',v4()),{
          notificationTitle:`We have Approved your payment for the ${plan} ${length == 'month' ? 'monthly': length == '3months'? '3 months' : 'Annual'  } subscription`,
          time:serverTimestamp(),
          seen:false,

   

        
      },{merge:true})
      ).then(
        deleteDoc(doc(firestore,'TransactionNewUsers',uid)).then(()=>{
      settransactions(prevArray => prevArray.filter(item => item.uid !== uid));
          
        })

                  )
      ))

  }

  
  function  decline(transactionID,paymentFor,uid,plan,length)
 {
    const data ={
        paid:false,
          state:'Declined',
          declinedReason:declinedReason
      }
      updateDoc(doc(firestore,'Transactions',transactionID),data).then(
        updateDoc(doc(firestore,'AccountDetails',uid,'Transaction',transactionID),data).then(
          updateDoc(doc(firestore,'TotalUsers',uid),{
            isPending:false,
          state:'Declined'

              
          }).then(
          updateDoc(doc(firestore,'TransactionNewUsers',uid),{
              paid:false,
            plan:'Free',
          state:'Declined'
  
              
         
      
              
            }).then(
                setreason(false),
       
                
                
                ).then(
                  updateDoc(doc(firestore,'TotalUsers',uid),{
                    paid:false,
                  plan:'Free'
                    
               
            
                    
                  })
                ).then(
                  setDoc(doc(firestore,'AccountDetails',uid,'Notification',v4()),{
                      notificationTitle:`We have declined your payment for the ${plan} ${length == 'month' ? 'monthly': length == '3months'? '3 months' : 'Annual'  } subscription`,
                      time:serverTimestamp(),
                      declinedReason:declinedReason,
                      seen:false,


               
            
                    
                  }).then(
        deleteDoc(doc(firestore,'TransactionNewUsers',uid)).then(()=>{
      settransactions(prevArray => prevArray.filter(item => item.uid !== uid));
          
        })

                  )
                )
        )
        )
        )

  }


  function handleDecline(transactionID,paymentFor,uid){
    setreason(true)

  }

  return (
    <div>
  <table class="w-full min-w-max table-auto text-left">
    <thead>
      <tr>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="text-center Mooli block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Transaction Id</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Account Holder</p>
        </th>

     

       
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Plan</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Amount</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Length</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Date</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Status</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Account</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Screenshot</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 text-center">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Action</p>
        </th>
      </tr>
    </thead>
  {
    transactions.map(i=>{
      const {accountHolder,accountNumber,amount,paymentFor,state,uid,buyerId,sellerId,screenshot,plan,length,courseTitle,transactionID,chosenMethod,paid,isPending,createdTime} = i
      return(
        <tbody>
        <tr>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <div class="flex items-center gap-3">
              {/* <img src="https://docs.material-tailwind.com/img/logos/logo-spotify.svg" alt="Spotify" class="inline-block relative object-center !rounded-full w-12 h-12 rounded-lg border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"/> */}
              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 ">{transactionID}</p>
            </div>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{accountHolder}</p>
          </td>
      
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{plan} </p>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{amount} </p>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{length} </p>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{(createdTime)}</p>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
            <div class="w-max">
             {
              state == 'Pending' ?
             
             <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-yellow-500/20 text-yellow-900 py-1 px-2 text-xs rounded-md" >
             <span class="">Pending</span>
             
           </div>
            :
            <div>
               {  state == 'Approved' &&     <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500 text-green-900 py-1 px-2 text-xs rounded-md" >
                <span class="">Completed</span>
              </div>}

{state == 'Declined' &&     <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-red-500 text-red-900 py-1 px-2 text-xs rounded-md" >
                <span class="">Declined</span>
              </div>}
            </div>
               

              


             }
            </div>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
         {
          chosenMethod == 'Cbe Birr'?
          <div class="flex items-center gap-3">
          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
          </div>
          <div class="flex flex-col">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
            </p>
          </div>
        </div>
        :
        chosenMethod == 'Telebirr'?
        <div class="flex items-center gap-3">
        <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
        </div>
        <div class="flex flex-col">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
          </p>
        </div>
      </div>
      :
      chosenMethod == 'Bank Of Abyssinia'?
      <div class="flex items-center gap-3">
      
      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
      </div>
      <div class="flex flex-col">
        <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
        </p>
      </div>
    </div>
    :

    <div class="flex items-center gap-3">
      
    <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
    </div>
    <div class="flex flex-col">
      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod} 
      </p>
    </div>
  </div>
    



         }
          </td>

          <td class="p-4 border-b border-blue-gray-50 text-center">
            <a class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal " target='_blank' href={screenshot}>Screenshot</a>
          </td>
          <td class="p-4 border-b border-blue-gray-50 text-center">
          <td class="p-4 border-b border-blue-gray-50">
            <a style={{width:"30%"}}  onClick={()=>handleDecline(transactionID,paymentFor,uid,plan)} class="mx-2 px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Decline</a>
   <a style={{width:"30%"}}  onClick={()=>handleApprove(transactionID,paymentFor,uid,plan,length)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Approve</a>

          </td>
          </td>
        </tr>
  {
    reason == true && 
        
  <div className='flex items-center'>
      <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}   onChange={e=>setdeclinedReason(e.target.value)}  type="text" class="border  focus:outline-none
                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="5000"></textarea>
   <a style={{width:"30%"}}  onClick={()=>decline(transactionID,paymentFor,uid,plan,length)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Decline</a>

                 
  </div>
  }
    
     
      </tbody>
      )
    })
  }
  </table>
    </div>
  )
}
