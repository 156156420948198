import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard';
import User from './user';
import Complaints from './complaints';
import Withdrawal from './Withdrawal';
import CourseReview from './courseReview';
import LectureReview from './lectureReview';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route   path='/' element={<Dashboard/>} />
      <Route   path='/user' element={<User/>} />
      <Route   path='/complaints' element={<Complaints/>} />
      <Route   path='/withdrawal' element={<Withdrawal/>} />
      <Route   path='/coursereview' element={<CourseReview/>} />
      <Route   path='/coursereview/:courseId/:lectureId' element={<LectureReview/>} />




      <Route   path='/course' element={<App/>} />



    </Routes>
  
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
