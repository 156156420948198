import { collection, onSnapshot, orderBy, query, where,doc, setDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { firestore } from './firebase'
import { Link, useParams } from 'react-router-dom'

export default function LectureReview() {
    const [courses,setcourses] = useState([])
    const [selectedCourse,setselectedCourse] = useState([])
    const [Test,setTest] = useState([])

    const [lectures,setlectures] = useState([])
    const [progress,setprogress] = useState()
    const [textOpen,settextOpen] = useState(false)
    const [approveTextOpen,setapproveTextOpen] = useState(false)
    const {lectureId,courseId} = useParams()

    const [declinedReason,setdeclinedReason] = useState()
    const [approvedReason,setapprovedReason] = useState()






    const [pop,setpop] = useState(false)
 

    function quizPop(courseId){
   

        setpop(true)

    }
    function quizPopClose(){
        setpop(false)

        
    }
    const [showPlayButton, setShowPlayButton] = useState(true);
    const videoRef = useRef(null);

    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
        setShowPlayButton(false);
     
      }
    };
    function handleDecline(courseId,instructorId){
      const course1 = doc(firestore,'Courses',courseId)
      const course2 = doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId)
      const data = {
        status : 'unpublished',
        reason:declinedReason
      }
      setDoc(course1,data,{merge:true})
      setDoc(course2,data,{merge:true})
      settextOpen(false)



    }
    function handleApprove(courseId,instructorId){
      const course1 = doc(firestore,'Courses',courseId)
      const course2 = doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId)
      const data = {
        status : 'published',
        reason:approvedReason
      }
      setDoc(course1,data,{merge:true})
      setDoc(course2,data,{merge:true})
      setapproveTextOpen(false)



    }
    function handleDeclineRemove(){
      settextOpen(false)

    }
    function declineReasonSet(){
      settextOpen(true)
    }

    function handleApproveRemove(){
      setapproveTextOpen(false)

    }
    function approveReasonSet(){
      setapproveTextOpen(true)
    }

    useEffect(()=>{
        const lecture = doc(firestore,'Courses',courseId,'lectures',lectureId)
        onSnapshot(doc(firestore,'Courses',courseId),doc=>{
            let temp = []
            temp.push({...doc.data()})
            setselectedCourse(temp)

        })
        const TestRef = doc(firestore,"Courses",courseId,"lectures",lectureId,'quiz',lectureId);
  
        onSnapshot(TestRef,snapshot=>{
          let main =[]
            if(snapshot.exists()){
              console.log(snapshot.data())
        
            main.push({...snapshot.data(),id:snapshot.id})
            main.forEach((item,index) => {
              setTest(item.question)
            
              
            });
        
          }
         
         
        
        
        })
               

        onSnapshot(lecture ,doc=>{
          let temp = []
            temp.push({...doc.data()})

          setlectures(temp)

      })
    },[])
    const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString();
      };

  return (
 
    <div className='flex'>
                           {
                                   pop == true &&
                                   Test.map((i, index) => {
                                       const { input, choices,answer } = i;
                                       return (
                         <div className='popOpener'>
<a onClick={quizPopClose}>Back</a>

                                        <div key={index} >
                                          <p className='borel mb-2' style={{ fontSize: '17px' }}>
                                            <span style={{ fontSize: '20px' }} className='underline capitalize mr-1 badScript'>{answer}</span>{index + 1}. {input}
                                          </p>
                                          <div className='mb-10'>
                                            {choices.map((choice, choiceIndex) => {
                                              return (
                                                <p key={choiceIndex} className=' borel ml-8 mb-1.5' style={{ fontSize: '16px' }}>
                                                  {choice}
                                                </p>
                                              );
                                            })}
                                          </div>
                         </div>
                                        </div>
                                       );
                                       })
                            }
{
    pop == false &&
    <div className='flex'>
            <div class="relative  mx-auto mt-12 flex flex-col  "style={{width:"30%",height:"60%"}}>
{
lectures.map(i=>{
const {lectureVideo,lectureTitle,lectureQuiz} = i
return(
lectureVideo && lectureVideo.length != 0 ?
<div>
<label  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">

<div class="flex justify-center m-auto w-full " style={{height:"500px"}} >


<div class="video-container mr-4">
<video controls>
<source src={lectureVideo} type="video/mp4"></source>
Your browser does not support the video tag.
</video>
</div>

</div> 
</label>

</div> 
:
<div class="ml-4 flex items-center justify-center m-auto w-full" >
<label  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64  border-gray-300  rounded-lg cursor-pointer bg-white ">
<img   class="h-auto w-full rounded-lg"  src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>

<p  className='borel text-center mt-4'>Click here to upload a lecture video</p>

</label>
</div> 
)
})

// :





}


</div>
{
lectures.map(i=>{
const {lectureQuiz,lectureTitle,lectureDescription,lecturePdf,lectureAudio,lectureImg} = i
return(
<div className=""style={{width:"70%"}}>


<div className='border rounded m-auto' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Title </h3>
{
<p className="borel text-gray-500 block"id='editCourseTitleDesktop' style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{lectureTitle}</p>

}


</div>




</div>


<div>

</div>

</div>
<div className='border rounded m-auto mb-5' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Description </h3>
{
<p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}}>{lectureDescription}</p>

}

</div>



</div>


<div>

</div>

</div>

{
lecturePdf ?

<div className='border rounded m-auto' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Pdf</h3>
<a target='_blank' className="borel text-gray-500 block hover:underline" style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lecturePdf}>File</a>
</div>


</div>

<div>

</div>

</div>
:
<div>

</div>
}

{
lectureImg ?

<div className='border rounded m-auto' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Image</h3>
<a className="borel text-gray-500 block hover:underline"target='_blank' style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} href={lectureImg}>Image</a>
</div>
<div>
    
</div>
</div>

<div>

</div>

</div>
:
<div>

</div>
}

{
lectureAudio ?

<div className='border rounded m-auto' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Audio</h3>
<audio controls>
<source src={lectureAudio} type="audio/mpeg" />
Your browser does not support the audio element.
</audio>
</div>
<div>

</div>
</div>

<div>

</div>

</div>
:
<div>

</div>
}

{
lectureQuiz ?

<div className='border rounded m-auto' style={{width:"70%"}}>

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Lecture Quiz</h3>
<div onClick={quizPop} className="borel text-gray-500 block hover:underline" style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} >Quiz</div>
</div>


</div>

<div>

</div>

</div>
:
<div>

</div>
}
</div>
)
})
}
    </div>
}
</div>
  )
}
