import { collection, onSnapshot, orderBy, query, where,doc, setDoc, serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { firestore } from './firebase'
import { Link, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import moment from 'moment'

export default function CourseReview() {
    const [courses,setcourses] = useState([])
    const [selectedCourse,setselectedCourse] = useState([])
    const [Test,setTest] = useState([])

    const [lectures,setlectures] = useState([])
    const [progress,setprogress] = useState()
    const [textOpen,settextOpen] = useState(false)
    const [approveTextOpen,setapproveTextOpen] = useState(false)

    const [declinedReason,setdeclinedReason] = useState()
    const [approvedReason,setapprovedReason] = useState()
    const currentTime = moment();
    const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
    const currentYear = currentMoment.year();
    const currentMonth = currentMoment.month();
    const currentDay = currentMoment.date();
    
    let currentDateTime = moment([currentYear, currentMonth, currentDay]);





    const [pop,setpop] = useState(false)
    const [testPopOpen,settestPopOpen] = useState(false)

 
function testPop(courseId){
  settestPopOpen(true)
  setpop(false)

  const TestRef = doc(firestore,"Courses",courseId,"Test",courseId);
  
  onSnapshot(TestRef,snapshot=>{
    let main =[]
      if(snapshot.exists()){
        console.log(snapshot.data())
  
      main.push({...snapshot.data(),id:snapshot.id})
      main.forEach((item,index) => {
        setTest(item.question)
      
        
      });
  
    }
   
   
  
  
  })

}
function testPopClose(){
  settestPopOpen(false)
  setpop(true)


}
    function popOpen(courseId){
      const lecture = collection(firestore,'Courses',courseId,'lectures')
      const q = query(lecture, orderBy('createdTime'));
        onSnapshot(doc(firestore,'Courses',courseId),doc=>{
            let temp = []
            temp.push({...doc.data()})
            setselectedCourse(temp)

        })

        onSnapshot(q,doc=>{
          let temp = []
          doc.forEach(snapshot=>{
            temp.push({...snapshot.data()})

          })
          setlectures(temp)

      })

        setpop(true)

    }
    function popClose(){
        setpop(false)

        
    }
    const [showPlayButton, setShowPlayButton] = useState(true);
    const videoRef = useRef(null);

    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
        setShowPlayButton(false);
     
      }
    };
    function handleDecline(courseId,instructorId){
      const course1 = doc(firestore,'Courses',courseId)
      const course2 = doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId)
      const data = {
        status : 'unpublished',
        reason:declinedReason
      }
      setDoc(course1,data,{merge:true})
      setDoc(course2,data,{merge:true})
      settextOpen(false)



    }
    function handleApprove(courseId,instructorId,courseTitle){
      const course1 = doc(firestore,'Courses',courseId)
      const course2 = doc(firestore,'AccountDetails',instructorId,'CoursesCreated',courseId)
      const data = {
        status : 'published',
        reason:approvedReason
      }
      setDoc(course1,data,{merge:true})
      setDoc(course2,data,{merge:true}).then(
        setDoc(doc(firestore,'AccountDetails',instructorId,'Notification',v4()),{
          notificationTitle:`Having reviewed your ${courseTitle} course, we are pleased to announce that it has been published`,
          time:serverTimestamp(),
          declinedReason:approvedReason,
          
          seen:false,


   

        
      })
      )
      setapproveTextOpen(false)



    }
    function handleDeclineRemove(){
      settextOpen(false)

    }
    function declineReasonSet(){
      settextOpen(true)
    }

    function handleApproveRemove(){
      setapproveTextOpen(false)

    }
    function approveReasonSet(){
      setapproveTextOpen(true)
    }

    useEffect(()=>{
        const  courseList = collection(firestore,'Courses')

      
        const q = query(courseList, where('status','==','pending'));

        onSnapshot(q,snapshot=>{
            let temp = []
            snapshot.docs.forEach(doc=>{
                temp.push({...doc.data()})
            })
            setcourses(temp)
        })
    },[])
    const convertTimestampToDate = (timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString();
      };

  return (
    <div>
          {
                                   testPopOpen == true &&
                                   
                                  <div>
                                    <p onClick={testPopClose}>Back</p>

                                  {
                                    Test.map((i, index) => {
                                      const { input, choices,answer } = i;
                                      return (
                                        <div key={index} >
                                          <p className='borel' style={{ fontSize: '17px' }}>
                                            <span style={{ fontSize: '20px' }} className='underline capitalize mr-1 badScript'>{answer}</span>{index + 1}. {input}
                                          </p>
                                          <div className='mb-10'>
                                            {choices.map((choice, choiceIndex) => {
                                              return (
                                                <p key={choiceIndex} className=' borel ml-8 ' style={{ fontSize: '16px' }}>
                                                  {choice}
                                                </p>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )
                                    })
                                }
                                  </div>

                            }
        {
            pop == true &&
            selectedCourse.map(i=>{
                const {isTest,coursePricing,courseId,instructorId, courseTitle,coursePrice,pricingDescription,courseThumbnail,courseTrailer,courseDescription,courseSubject,courseSkills,courseLevel,completionTime,intendedAgeGroup} = i

                return(
                    <div className='popOpener' >
                   <div className='relative'>
                 
    
    
     
    
 <div className='flex justify-between px-5'>
             
            <div style={{width:"58%"}}>
                  
        
                    <table class="w-full text-sm text-left text-gray-500   border mt-5 mb-10">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" class="px-6 py-3 Mooli">
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                        Lecture
                    </th>
                   
                
                  
                
                    <th scope="col" class="px-6 py-3 Mooli">
                    PDF
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Audio
    
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Quiz
    
                    </th>
                    <th scope="col" class="px-6 py-3 Mooli">
                    Action
    
                    </th>
    
                </tr>
            </thead>
            <tbody>
    {
    lectures.map(({ lectureTitle, lectureId, lectureOrder,lectureAudio, id ,lecturePdf,lectureQuiz}, index) => {
      return (
        <tr className="bg-white border-b" key={index}>
          <td className="px-6 py-4 capitalize Mooli">
            <ol>
              <li>{index + 1}</li>
            </ol>
          </td>
          <td className="flex px-6 py-4 capitalize">
            <Link to={`/coursereview/${courseId}/${lectureId}`}>
              <p className="cursor-pointer Mooli underline text-gray-800">{lectureTitle}</p>
            </Link>
          </td>
          <td className="px-6 py-4">
         {!lecturePdf  ?    <img
              style={{ width: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
              alt=""
            /> :
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
            alt=""
          />
            }
          </td>
          <td className="px-6 py-4 text-center">
        {
            lectureAudio  ? 
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
            alt=""
          />
          :
          <img
          style={{ width: "20px" }}
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
          alt=""
        /> 
         }
          </td>
          <td className="px-6 py-4 text-center">
        
            {
              lectureQuiz == true ? 
              <img
              style={{ width: "20px" }}
              src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/check-mark.png?alt=media&token=20c868d3-801f-45a2-a86f-77c55585a5d9"
              alt=""
            />
            :
            <img
            style={{ width: "20px" }}
            src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3"
            alt=""
          /> 
            }
          </td>
          <td className="px-6 py-4 fjalla relative">
       
          </td>
        </tr>
      )
    })
    }
    </tbody>
    
    </table>
    
    {
      lectures.length  == 0 &&
      <div className='w-full text-center fjalla capitalize text-red-800' style={{fontSize:"20px"}}>
      Your course does not have any lectures 
      <p className="fjalla text-gray-500 normal-case" style={{fontSize:"12px"}}>Make sure to create lectures</p>
    </div> 
    
    }
    
    {/* <div className='mt-12 mb-5'>
    <h1 className='anton text-blue-800 flex  items-center capitalize' style={{color:"blue",fontSize:"25px"}}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
      <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
    </svg>Set up your course pricing</h1>
    
    </div>
    
    <div className='border ' style={{width:"90%"}}>
      <div className='flex justify-between w-full items-center px-5 pt-5'>
        <h1 className=' text-center anton capitalize  'style={{fontSize:"20px"}}></h1>
        <p className='Mooli cursor-pointer hover:underline'>Need Help</p>
      </div>
    <div className='flex flex-col justify-between w-full items-center'>
    <h1 className='fjalla text-center text-gray-900 mt-5' style={{fontSize:"20px"}}>Our flexible pricing plans let you choose whether to charge for your course or make it free. </h1>
    <p className='borel text-gray-500 text-center'style={{fontSize:"15px"}}>Your course's pricing plan has not been set</p>
        
        <button class=" m-auto px-5 mt-4 py-3 text-blue-600 hover:text-blue-700 focus:text-blue-700 hover:bg-blue-100 focus:bg-blue-100 border border-blue-600 rounded-md mb-3">
            
                Add Pricing Plan 
              </button>
  
              
    
    </div>
    </div> */}
    {
isTest ?

<div className='border rounded m-auto' >

<div className='flex justify-between px-5 py-5 '>
<div>
<h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Test</h3>
<div onClick={()=>testPop(courseId)} className="borel text-gray-500 block hover:underline" style={{fontSize:"16px",maxWidth:"100%",wordBreak:"break-all"}} >Test</div>
</div>


</div>

<div>

</div>

</div>
:
<div>

</div>
}
     <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Completion Time </h3>
                      
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{completionTime}</p>
  
           
                         </div>
            
    
      
                      
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Level </h3>
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseLevel}</p>
  
           
                         </div>
                      
                      
                          </div>
                  
                     
                          <div>
                      
                          </div>
                                  
                        </div>
   <div className='border rounded mt-8 ' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Intended Age Group </h3>
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{intendedAgeGroup}</p>
           
                         </div>
                      
  
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
  <div className='border rounded m-auto mt-8' >
                    
                    <div className='flex justify-between px-5 py-5 w-full '>
                   <div className='w-full'>
                    <div className='flex justify-between w-full'>
                   <h3 class="  home_description-header pb-5 " style={{fontSize:"20px"}}>Course Description </h3>
  
                   
                    </div>
     {
     <p className="borel text-gray-500 block"id='editCourseDescripionDesktop' style={{fontSize:"13px",maxWidth:"100%",wordBreak:"break-all"}}>{courseDescription}</p>
      
     }
                   </div>
               
               
         
                    </div>
                 
             
   
                    <div>
                
                    </div>
                            
                  </div>
                  <div className='border rounded my-8' >
                          <div className='flex justify-between items-center px-5 py-5 w-full'>
                         <div className='w-full'>
            <div className='flex justify-between w-full items-center'>
            <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Skills </h3>
                     
            </div>
                         {
                          <div className='gridCourseSkill'>
                          {
                            courseSkills.map(i=>{
                              return(
                                <span style={{fontSize:"11px",rowGap:'5px',paddingLeft:'5px',paddingRight:'5px',paddingTop:'5px',paddingBottom:'5px'}} class="mx-1 mb-2 capitalize bg-green-200 text-green-600  rounded fjalla">{i}</span>
                
                              )
                            })
                          }
                         </div>
  
  
                         }
           
                         </div>
                
                         
                   
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
  
    
                      </div>
                      <div className=' ' style={{width:"38%"}}>
                        {/* <h1 className='pl-5 pb-2   anton text-blue-800 flex  items-center text-center capitalize' style={{color:"blue",fontSize:"25px"}}>
                    Customize your course</h1> */}
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Title </h3>
                         {
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseTitle}</p>
  
                         }
           
                         </div>
                  
                  
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                    
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Pricing </h3>
                         {
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePricing} </p>
  
                         }
           
                         </div>
                  
                  
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Price </h3>
                         {
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{coursePrice} ETB</p>
  
                         }
           
                         </div>
                  
                  
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Pricing Description </h3>
                         {
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{pricingDescription} </p>
  
                         }
           
                         </div>
                  
                  
                          </div>
                        
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div className='border rounded mt-6' >
                          <div className='flex justify-between px-5 py-5 '>
                         <div>
                         <h3 class="  home_description-header pb-4 " style={{fontSize:"20px"}}>Course Subject </h3>
           <p className="borel text-gray-500 block" style={{fontSize:"14px",maxWidth:"100%",wordBreak:"break-all"}}>{courseSubject}</p>
  
           
                         </div>
                    
                  
                          </div>
                       
                     
                          <div>
                      
                          </div>
                                  
                        </div>
                        <div>
                        <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
  
                    {
    
    
    courseTrailer  ? 
    <div class="relative flex flex-col items-center w-full">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
    </div>
        </label>
  {
      <div className=" mx-auto mb-20 rounded-md" >
    
      <video src={courseTrailer} width="640" height="480" controls>
  Your browser does not support the video tag.
</video>
    
    </div>

  }
      
     
    </div>
    
    :
      <div class="flex flex-col items-center justify-center">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Trailer</h3>
            
      </div>
        </label>
    <img class="h-auto w-full rounded-lg" src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description"/>
    
    </div> 
    
  
    
    }
    
    
    
    
                          </div>
    
                    </div>
  
  
  
  
  
  
                    <div className=' border mt-6 px-5 rounded'>
                    <div className='w-full  flex   items-center justify-between pb-5  pt-3' style={{borderRadius:"20px"}}>
  
    
                    {
    
    
    courseThumbnail  ? 
    <div class="relative flex flex-col items-center w-full ">
        <label for="dropzone-file" class="w-full    ">
      <div className="flex justify-between w-full py-4 items-center">
      <h3 class="  home_description-header " style={{fontSize:"20px"}}>Course Thumbnail</h3>

            </div>
        </label>
       
    <img    class="h-auto w-full rounded-lg" src={courseThumbnail} alt="image description"/>
   
    
    </div>
    
    :
     
    
    <div className='w-full '>
    <div class="flex   flex-col items-center justify-center m-auto w-full" >
        
        <label for="dropzone-file" class="text-center relative flex flex-col items-center justify-center w-full   border-gray-300  rounded-lg   ">
        {
            progress != 100 & progress == 0 ?  <div className='flex flex-col items-center'>
            <svg  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          
             
            <button  class=" inline-flex px-5 py-3 text-white bg-blue-700 hover:bg-blue-800 focus:bg-blue-700 rounded-md  mb-3">
                     
                        Upload Thumbnail
                      </button>
                      <p className='fjalla text-center '>
             
                </p> 
            </div> 
            :
             progress == 100 ? <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             <div class="">
             <svg  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="cancelUploadDesktop bi bi-x" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
             <p className='fjalla  text-center'>File Successfully Uploaded</p>
         </div>
         
               </div>
               :
               <div className=' flex items-center justify-center my-5 rounded m-auto ' id='loadingPricingDesktop' style={{width:"90%",height:"95%"}}>
             
             <div class="relative">
             <div class="w-20 h-20 border-blue-200 border-2 rounded-full"></div>
             <div class="w-20 h-20 border-blue-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"></div>
             <p className='fjalla mt-4 text-center'>Uploading</p>
         </div>
         
               </div>
        }
    
    
    
        </label>
    </div> 
    </div> 
    
    }
    
    
    
    
                          </div>
    
                    </div>
    
                        </div>
                      </div>
            </div>
                    </div>
                
                        <div  onClick={approveReasonSet}class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700 m-auto">
   <a   >Approve</a>
  
            </div>
            {
                 approveTextOpen == true && 
        
                 <div className='flex items-center'>
                     <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}   onChange={e=>setapprovedReason(e.target.value)}  type="text" class="border  focus:outline-none
                                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                border-gray-300 rounded-md" maxLength="5000"></textarea>
                  <a style={{width:"30%"}}  onClick={()=>handleApprove(courseId,instructorId,courseTitle)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-green-800 border border-green-600 rounded-lg hover:bg-green-700">Approve</a>
                  <a style={{width:"30%"}}  onClick={()=>handleApproveRemove()} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Cancel</a>
               
                                
                 </div>
                 }
            
            <div onClick={declineReasonSet} class="mt-2 px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150   border  rounded-lg red-Bg m-auto">
   <a   >Decline</a>
  
            </div>
            {
                 textOpen == true && 
        
                 <div className='flex items-center'>
                     <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}   onChange={e=>setdeclinedReason(e.target.value)}  type="text" class="border  focus:outline-none
                                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                                border-gray-300 rounded-md" maxLength="5000"></textarea>
                  <a style={{width:"30%"}}  onClick={()=>handleDecline(courseId,instructorId)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-800 border border-red-600 rounded-lg hover:bg-red-700">Decline</a>
                  <a style={{width:"30%"}}  onClick={()=>handleDeclineRemove()} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Cancel</a>
               
                                
                 </div>
                 }
            

                    </div>
                )
            })
        }
        {
          testPopOpen == false &&
          <table className="w-full text-sm text-left text-gray-500 border mt-5" style={{ maxWidth: '100%' }}>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
            <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Thumbnail
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Title
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Creation date
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Subject
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Price
              </th>
              <th scope="col" className="py-3 Mooli text-center" style={{ fontSize: '11px' }}>
                Status
              </th>
         
            </tr>
          </thead>
        
          <tbody>
            {courses.map((course, index) => {
              const { courseTitle, courseId, courseThumbnail, courseSubject, tus, createdTime, coursePrice } = course;
              return (
                <tr className="bg-white border-b" key={courseId}>
                  <th>{index + 1}</th>
                  <th className="py-4 pl-3">{courseThumbnail.length !== 0 ? (
                    <img className="h-auto max-w-lg rounded-lg text-center" style={{ height: "80px" }} src={courseThumbnail} alt="image description" />
                  ) : (
                    <img className="h-auto max-w-lg rounded-lg text-center" style={{ height: "80px" }} src="https://github.com/themesberg/flowbite/blob/main/static/images/examples/image-1@2x.jpg?raw=true" alt="image description" />
                  )}
                  </th>
                  <th scope="row" className="underline fjalla text-center font-medium text-gray-900 whitespace-nowrap">
                      <span onClick={()=>popOpen(courseId)} className="hover:text-blue-800 text-center cursor-pointer capitalize borel hover:text-blue-700 text-gray-600" style={{ fontSize: "13px" }}>{courseTitle.slice(0, 100)}</span>
                  </th>
                  <td className="mx-auto borel text-center" style={{ fontSize: "12px" }}>
                    {createdTime && convertTimestampToDate(createdTime)}
                  </td>
                  <td className="mx-auto borel text-center" style={{ fontSize: "12px" }}>
                    {courseSubject} 
                  </td>
                  <td className="mx-auto borel text-center" style={{ fontSize: "12px" }}>
                    {coursePrice} ETB
                  </td>
                  <td className="mx-auto fjalla capitalize text-center" style={{ fontSize: "12px" }}>
                  </td>
                  <td className="mx-auto fjalla relative text-center">
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        }
  
  </div>
  )
}
