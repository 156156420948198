import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { addDoc, collection, doc, getFirestore, onSnapshot,getDoc,deleteDoc,setDoc,updateDoc,where,serverTimestamp, arrayUnion, increment, query } from 'firebase/firestore';
import { db, firestore } from './firebase';
import { initializeApp } from 'firebase/app';
import { v4 } from 'uuid';
import moment from 'moment';

function App() {
  const [totalUsers,setTotalUsers] = useState([])
  const [teachers,setteachers] = useState([])
  const [students,setstudents] = useState([])
  const [transactions,settransactions] = useState([])
  const [studentName,setstudentName] = useState('')
  const [declinedReason,setdeclinedReason] = useState('')
  const [alreadyExists,setalreadyExists] = useState('')
  const [success,setsuccess] = useState(false)


  const firestore = getFirestore();

  const [reason,setreason] = useState(false)



  const [earning,setEarning] = useState()
  const [totalStudents,setTotalStudents] = useState()

  const [student,setstudent] = useState({})



  

  useEffect(()=>{
   
    const totalUsersCollectionRef = collection(db, 'TotalUsers');
    
    onSnapshot(totalUsersCollectionRef, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        setTotalUsers(temp);
      });
    });

    const transactions = collection(db, 'TransactionCourses');
    const q = query(transactions,where('isPending' ,'==', true))
    
    onSnapshot(q, (snapshot) => {
      let temp = []
      snapshot.forEach((snapshot) => {
        temp.push({ ...snapshot.data() })
        settransactions(temp);
        const id = snapshot.data().buyerId
    onSnapshot(doc(firestore,'TotalUsers',id),snapshot=>{

          let temp = []
            temp.push({ ...snapshot.data() })
            setstudentName(snapshot.data().fullName)
        });
       
      });
    });


    const StudentsCollectionRef = collection(db, 'Students')
    
    onSnapshot(StudentsCollectionRef, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        setstudents(temp);
      });
    });
    

    const teachersCollectionRef = collection(db, 'Teacher');
    
    onSnapshot(teachersCollectionRef, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        setteachers(temp);
      });
    });

    const transaction = collection(db, 'TotalUsers');

    onSnapshot(teachersCollectionRef, (snapshot) => {
      let temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data() })
        setteachers(temp);
      });
    });
  },[])

  const convertTimestampToDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString();
  };

  const currentTime = moment();
  const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
  const currentYear = currentMoment.year();
  const currentMonth = currentMoment.month();
  const currentDay = currentMoment.date();
  const currentHour = currentMoment.hour();
  const currentMinute = currentMoment.minute();
  const currentSecond = currentMoment.second();
let currentDateTime = moment([currentYear, currentMonth, currentDay, currentHour, currentMinute, currentSecond]);


  async function handleApprove(
    transactionID,
    buyerId,
    sellerId,
    courseId,
    price,
    courseTitle
  ) {
    try {
      const buyerSnapshot = await getDoc(doc(firestore, 'TotalUsers', buyerId));
      const buyerData = buyerSnapshot.data();
      const studentName = buyerData.name;
  
      const data = {
        paid: true,
        isPending: false,
        state: 'Approved',
        course:courseId,
      };
  
      const documentRef = doc(firestore, 'AccountDetails', sellerId, 'Students', buyerId);
  
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
  
        await setDoc(documentRef, {
        
              progress: {
      
       
          },
          tookTest:false,
          studentId: buyerId,
          enrollmentDate: currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
          paid: parseInt(price, 10),
          enrolledFor: arrayUnion(courseId),
          status: 'Active',
          courseId: courseId
        },{merge:true});
      } else {
        // If the document doesn't exist, create it
        await setDoc(documentRef, {
               progress: {
      
       
          },
          tookTest:false,
        studentId: buyerId,
          enrollmentDate: currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
          paid: parseInt(price, 10),
          enrolledFor: arrayUnion(courseId),
          status: 'Active',
          courseId: courseId
        },{merge:true});
      }
  

      await setDoc(
        doc(firestore, 'AccountDetails', sellerId, 'Transaction', courseId + buyerId),
        data,{merge:true}
      );
  
      await setDoc(
        doc(
          firestore,
          'AccountDetails',
          sellerId,
          'CoursesCreated',
          courseId,
          'Students',
          buyerId
        ),
        {
          progress: {
      
       
          },
          tookTest:false,
          studentId: buyerId,
          enrollmentDate: currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
          paid: parseInt(price, 10),
          enrolledFor: arrayUnion(courseId),
          status: 'Active',
          courseId: courseId
        }
      );
  
      await setDoc(doc(firestore, 'AccountDetails', sellerId, 'Notification', v4()), {
        notificationTitle: `${studentName} paid for ${courseTitle}`,
        time: currentDateTime.format('YYYY-MM-DD HH:mm:ss')
      });
  
  
  
      await setDoc(
        doc(firestore, 'AccountDetails', buyerId, 'Transaction', courseId + buyerId),
        data,{merge:true}
      );
  
      await setDoc(
        doc(firestore, 'AccountDetails', buyerId, 'Courses', courseId),
   {     progress: {
      
       
        },
        paymentStatus:'Approved',
        enrollmentDate: currentDateTime.format('YYYY-MM-DD HH:mm:ss'),
        isPending:false,
        courseId:courseId,
        completed:false,
        progressPercentage:0


      
      }, 
          
        { merge: true }
      );
  
      await setDoc(doc(firestore, 'AccountDetails', buyerId, 'Notification', v4()), {
        notificationTitle: `We have Approved your payment for ${courseTitle}`,
        time: currentDateTime.format('YYYY-MM-DD HH:mm:ss')
      });
  
      await setDoc(doc(firestore, 'Transactions', courseId + buyerId), data,{merge:true});
      await setDoc(doc(firestore, 'TransactionCourses',courseId + buyerId), data,{merge:true});

  
  
      const enrollmentsRef = doc(
        firestore,
        'AccountDetails',
        buyerId,
        'Enrollments',
        courseId
      );
  
      const enrollmentData = {
        courseId: courseId,
        userId: buyerId,
        enrollmentDate: currentDateTime.format('YYYY-MM-DD HH:mm:ss')
      };
  
      await setDoc(enrollmentsRef, enrollmentData);
    } catch (error) {
      console.error('Error handling approval:', error);
    }
      setsuccess(true);
  
     
  
  
      
    
  }
  function handleDecline(transactionID,buyerId,sellerId,courseId){
    const data ={
      paid:false,
      isPending:false,
      state: 'Declined',
      declinedReason:declinedReason


    }
    updateDoc(doc(firestore,'TransactionCourses',transactionID),data,{merge:true}).then(
      updateDoc(doc(firestore,'AccountDetails',buyerId,'Transaction',transactionID),data,{merge:true}).then(
      updateDoc(doc(firestore,'AccountDetails',sellerId,'Transaction',transactionID),data,{merge:true}).then(

      deleteDoc(doc(firestore,'AccountDetails',buyerId,'Courses',courseId)).then(
      )
      )

      )
    )
  }

  
  function decline(transactionID,paymentFor,uid){
    setreason(true)

  }

  return (

    <div className="App">
      {totalUsers.map(i=>{
        const{firstName} = i
        return(
          firstName
        )
      })}
      <input type='email'/>
        <div style={{display:'flex'}}>
          Total Users- {totalUsers.length}
          
        </div>

        <div style={{display:'flex'}}>
          Total Teachers- {teachers.length}
          
        </div>

        <div style={{display:'flex'}}>
          Total Students- {students.length}
          
        </div>

        <table class="w-full min-w-max table-auto text-left">
    <thead>
      <tr>
      <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="text-center Mooli block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Transaction Id</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Student's Name</p>
        </th>

     

        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Course</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Amount</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Date</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Status</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70">Account</p>
        </th>
        <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
          <p class="Mooli text-center block antialiased font-sans text-sm text-gray-900 font-normal leading-none opacity-70"></p>
        </th>
      </tr>
      {totalStudents}
    </thead>
  {
    transactions.map(i=>{
      const {accountHolder,accountNumber,coursePrice,state,courseId,buyerId,sellerId,screenshot,price,courseTitle,transactionID,chosenMethod,paid,isPending,createdTime} = i
      return(
        <tbody>
        <tr>
          <td class="p-4 border-b border-blue-gray-50">
            <div class="flex items-center gap-3">
              {/* <img src="https://docs.material-tailwind.com/img/logos/logo-spotify.svg" alt="Spotify" class="inline-block relative object-center !rounded-full w-12 h-12 rounded-lg border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"/> */}
              <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 ">{transactionID}</p>
            </div>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{accountHolder}</p>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{courseTitle}</p>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{price} ETB</p>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal">{createdTime}</p>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <div class="w-max">
             {
              state == 'Approved' ?
              <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-green-500/20 text-green-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
              <span class="">Paid</span>
            </div>
            :
            state == 'Declined' ?
            <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-red-500/20 text-red-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
            <span class="">Declined</span>
          </div>
          :
          <div class="relative grid items-center font-sans font-bold uppercase whitespace-nowrap select-none bg-yellow-500/20 text-yellow-900 py-1 px-2 text-xs rounded-md" style={{opacity: 1}}>
          <span class="">Pending</span>
        </div>
             }
            </div>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
         {
          chosenMethod == 'Cbe Birr'?
          <div class="flex items-center gap-3">
          <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
            <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/CBE-Birr-Logo-768x532.webp?alt=media&token=ebb27141-33f2-4e4d-a8b0-787d482e1d38" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
          </div>
          <div class="flex flex-col">
            <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
            </p>
          </div>
        </div>
        :
        chosenMethod == 'Telebirr'?
        <div class="flex items-center gap-3">
        <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
          <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/Telebirr.jpg?alt=media&token=4fdf55b7-e94e-4c45-a7ea-f26747e486b3" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
        </div>
        <div class="flex flex-col">
          <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
          </p>
        </div>
      </div>
      :
      chosenMethod == 'Bank of Abyssinia'?
      <div class="flex items-center gap-3">
      <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
        <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R%20(1).jpeg?alt=media&token=0c58e7bc-6fc7-4875-be19-a7413b1138c5" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
      </div>
      <div class="flex flex-col">
        <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod}
        </p>
      </div>
    </div>
    :

    <div class="flex items-center gap-3">
    <div class="h-9 w-12 rounded-md border border-blue-gray-50 p-1">
      <img src="https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/R.jpeg?alt=media&token=acce6414-c3f5-4e38-9eed-6c4d33922ccb" alt="visa" class="inline-block relative object-center !rounded-none rounded-md h-full w-full object-contain p-1"/>
    </div>
    <div class="flex flex-col">
      <p class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal capitalize">{chosenMethod} 
      </p>
    </div>
  </div>
    



         }
          </td>

          <td class="p-4 border-b border-blue-gray-50">
            <a class="fjalla block antialiased font-sans text-sm leading-normal text-gray-900 font-normal " target='_blank' href={screenshot}>Screenshot</a>
          </td>
          <td class="p-4 border-b border-blue-gray-50">
            <a style={{width:"30%"}}  onClick={()=>decline()} class="mx-2 px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-red-600 border border-red-600 rounded-lg hover:bg-red-500">Decline</a>
   <a style={{width:"30%"}}  onClick={()=>handleApprove(transactionID,buyerId,sellerId,courseId,price,courseTitle)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Approve</a>

          </td>
        </tr>
        {
    reason == true && 
        
  <div className='flex items-center'>
      <textarea  style={{maxHeight:"300px",minHeight:"150px",resize:'none'}}   onChange={e=>setdeclinedReason(e.target.value)}  type="text" class="border  focus:outline-none
                 focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
                 border-gray-300 rounded-md" maxLength="5000"></textarea>
   <a style={{width:"30%"}}  onClick={()=>handleDecline(transactionID,buyerId,sellerId,courseId)} class=" px-5 w-full cursor-pointer py-5 text-sm font-medium text-center text-white transition duration-150 ease-linear bg-blue-800 border border-blue-600 rounded-lg hover:bg-blue-700">Decline</a>

                 
  </div>
  }
    
     
      </tbody>
      )
    })
  }
  </table>
    </div>
  );
}

export default App;
